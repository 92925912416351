import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import api from 'helpers/api';

class ReportTripType extends Component {
  constructor(props) {
    super(props);
    this.state = { category: props.match.params.category, types: [], loading: true };

    this.loadTypes = this.loadTypes.bind(this);
    this.renderType = this.renderType.bind(this);
  }

  componentDidMount() {
    document.title = 'Report Trip | Flamingo Admin';
    this.loadTypes();
  }

  loadTypes() {
    return api.get(`/report/types/${this.state.category}`)
      .then((res) => this.setState({ types: res.data.data, loading: false }))
      .catch((e) => this.setState({ loading: false }, () => alert(this.parseError(e))));
  }

  parseError(e) {
    return window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
  }

  renderType(type, i) {
    return (
      <Link className="fm-report-trip-type-card" key={i} to={{ pathname: `/report/trip/${this.props.match.params.tripId}/${type.category}/${type.id}`, state: { type } }}>
        <div className="fm-report-trip-type-card-icon">
          <Icon icon={type.icon} />
        </div>
        <div className="fm-report-trip-type-card-text">
          <p className="fm-report-trip-type-card-title">{ type.title }</p>
          <p className="fm-report-trip-type-card-description">{ type.description }</p>
        </div>
      </Link>
    );
  }

  render() {
    const { loading, types, category } = this.state;
    return (
      <div className="fm-report-trip-type">
        <NavigationBar title="Trip Report" subtitle={category.toUpperCase()} loading={loading} showBack={true} />
        <div className="fm-report-trip-type-content">
          { types.map(this.renderType) }
        </div>
      </div>
    );
  }
}

export default ReportTripType;
