import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Marker } from 'components/common/map';
import api from 'helpers/api';
import colors from 'helpers/colors';

class TaskPriority extends Component {
  constructor() {
    super();
    this.state = { tasks: [], loading: true };

    this.loadTasks = this.loadTasks.bind(this);
    this.renderTask = this.renderTask.bind(this);
  }

  componentDidMount() {
    document.title = `Tasks | Flamingo Admin`;
    this.loadTasks();
  }

  loadTasks() {
    this.setState({ loading: true });
    api.get('/task/priority')
      .then((res) => this.setState({ tasks: res.data.data, loading: false }))
      .catch((e) => this.setState({ loading: false }, () => alert(e.toString())));
  }

  handleAssign(taskId) {
    this.setState({ loading: true });
    api.post(`/task/${taskId}/assign`)
      .then(this.loadTasks)
      .catch((e) => this.setState({ loading: false }, () => alert(e.toString())));
  }

  descriptionForTask(task) {
    switch (task.status) {
      case 'available':
        return `Available.`;
      case 'reserved':
        return `Assigned to ${ task.assignedTo.firstName } ${ task.assignedTo.lastName }.`;
      default:
        return ``;
    }
  }

  renderUser(user, i) {
    return <p className="fm-task-priority-item-user" key={i}>{ user.firstName } { user.lastName } - { user.distance.toFixed(1) } km</p>;
  }

  renderTask(task, i) {
    const priority = {
      2: { className: 'fm-task-priority-item-title fm-task-priority-item-title-medium', title: 'MEDIUM PRIORITY TASK' },
      3: { className: 'fm-task-priority-item-title fm-task-priority-item-title-high', title: 'HIGH PRIORITY TASK' },
    };

    return (
      <div className="fm-task-priority-item" key={i}>
        <p className={ priority[task.priority].className }>{ priority[task.priority].title }</p>
        <div className="fm-task-priority-item-map">
          <Map options={{ center: { lat: task.vehicle.latitude, lng: task.vehicle.longitude }, zoom: 14 }} google={window.google}>
            <Marker position={{ lat: task.vehicle.latitude, lng: task.vehicle.longitude }} title={task.vehicle.registration} />
          </Map>
        </div>
        <Link className="fm-task-priority-item-content" to={{ pathname: `/vehicle/${task.vehicle.id}`, state: { vehicle: task.vehicle } }}>
          <div className="fm-task-priority-item-header">
            <p className="fm-task-priority-item-rego">{ task.vehicle.registration }</p>
            <p className="fm-task-priority-item-type" style={{ backgroundColor: colors.task[task.type] }}>{ task.type.toUpperCase() }</p>
            <p className="fm-task-priority-item-created">{ moment(task.createdAt).fromNow() }</p>
            <div className="fm-task-priority-item-status" style={{ backgroundColor: colors.taskStatus[task.status] }}></div>
          </div>
          <p className="fm-task-priority-item-details">{ this.descriptionForTask(task) }</p>
          { task.notes && <p className="fm-task-priority-item-notes">{ task.notes }</p> }
          { task.nearbyUsers && task.nearbyUsers.map(this.renderUser) }
        </Link>
        {
          (!this.state.loading && !task.assignedTo) &&
          <div className="fm-task-priority-item-buttons">
            <span className="fm-task-priority-item-button" onClick={() => this.handleAssign(task.id)}>Assign to Me</span>
            <Link className="fm-task-priority-item-button" to={`/task/${task.id}/assign`}>Assign to Team Member</Link>
          </div>
        }
      </div>
    );
  }

  render() {
    const { tasks, loading } = this.state;
    const refreshAction = { onClick: this.loadTasks, icon: 'sync-alt' };

    return (
      <div className="fm-task-priority">
        <NavigationBar title="Priority Tasks" loading={loading} rightActions={[refreshAction]} />
        <div className="fm-task-priority-info">
          <p className="fm-task-priority-info-text">If you are the best placed to attend a task please assign the task to yourself. Otherwise check with the nearest team member on Slack to ensure they can attend the task.</p>
          <p className="fm-task-priority-info-text"><b>High</b> priority tasks have a target of 30 mins. Attend immediately, do not complete any other tasks while enroute.</p>
          <p className="fm-task-priority-info-text"><b>Medium</b> priority tasks have a target of 1-2 hours.</p>
        </div>
        { tasks.map(this.renderTask) }
      </div>
    );
  }
}

export default TaskPriority;
