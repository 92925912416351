import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Loading from 'components/common/loading';
import Icon from 'components/common/icon';
import PopUp from 'components/common/pop-up';
import Toast from 'components/common/toast';
import Map, { Marker, Circle, Polygon } from 'components/common/map';
import location from 'helpers/location';
import history from 'helpers/history';
import api from 'helpers/api';
import colors from 'helpers/colors';
import distance from 'helpers/distance';

import VehicleTabs from './components/vehicle-tabs';

class Vehicle extends Component {
  constructor(props) {
    super(props);
    const preloadedVehicle = props.location.state ? props.location.state.vehicle : false;
    this.state = { vehicle: preloadedVehicle || {}, zones: [], tasks: [], tickets: [], loading: !preloadedVehicle, isRefreshing: true };

    this.loadVehicle = this.loadVehicle.bind(this);
    this.loadTasks = this.loadTasks.bind(this);
    this.loadTickets = this.loadTickets.bind(this);
    this.loadZones = this.loadZones.bind(this);
    this.handleVehicleLoad = this.handleVehicleLoad.bind(this);

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleRefreshError = this.handleRefreshError.bind(this);
    this.handleToot = this.handleToot.bind(this);
    this.handleDirections = this.handleDirections.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleTripReport = this.handleTripReport.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.showImei = this.showImei.bind(this);
    this.hideImei = this.hideImei.bind(this);
    this.handleCopyGps = this.handleCopyGps.bind(this);
    this.handleControl = this.handleControl.bind(this);
    this.inspectionCheck = this.inspectionCheck.bind(this);

    this.loadLocation = this.loadLocation.bind(this);
    this.locationCheck = this.locationCheck.bind(this);

    this.handleAPI = this.handleAPI.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handleInspection = this.handleInspection.bind(this);
    this.handleFirmware = this.handleFirmware.bind(this);
    this.handleIncident = this.handleIncident.bind(this);
    this.handleLocate = this.handleLocate.bind(this);
    this.handleSounds = this.handleSounds.bind(this);
    this.handleBurnout = this.handleBurnout.bind(this);
    this.handleReplace = this.handleReplace.bind(this);
    this.handleEditQR = this.handleEditQR.bind(this);
    this.handleEditRegistration = this.handleEditRegistration.bind(this);
    this.handleChangeRegion = this.handleChangeRegion.bind(this);
    this.handleChangeModel = this.handleChangeModel.bind(this);

    this.handleEndRide = this.handleEndRide.bind(this);
    this.handleCancelReservation = this.handleCancelReservation.bind(this);
    this.handleEndCollection = this.handleEndCollection.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleRevertPickup = this.handleRevertPickup.bind(this);
    this.handleLastPhoto = this.handleLastPhoto.bind(this);

    this.handleActivate = this.handleActivate.bind(this);
    this.handleSuspend = this.handleSuspend.bind(this);

    this.handleLock = this.handleLock.bind(this);
    this.handleUnlock = this.handleUnlock.bind(this);
    this.handleHeadlight = this.handleHeadlight.bind(this);
    this.handleLightFlicker = this.handleLightFlicker.bind(this);
    this.handleSpeedLimit = this.handleSpeedLimit.bind(this);
    this.handleThrottle = this.handleThrottle.bind(this);
    this.handleShutdown = this.handleShutdown.bind(this);
    this.handlePower = this.handlePower.bind(this);
    this.handleSpeedMode = this.handleSpeedMode.bind(this);
    this.handleResetSettings = this.handleResetSettings.bind(this);
    this.handleBeginnerMode = this.handleBeginnerMode.bind(this);
    this.handleBatteryCover = this.handleBatteryCover.bind(this);
    this.handleSanitise = this.handleSanitise.bind(this);
    this.confirmMechanicalLock = this.confirmMechanicalLock.bind(this);
    this.handleHelmetLock = this.handleHelmetLock.bind(this);

    this.renderControls = this.renderControls.bind(this);
    this.renderTask = this.renderTask.bind(this);
  }

  componentDidMount() {
    document.title = 'Vehicle Details | Flamingo Admin';

    this.loadVehicle();
    this.loadTasks();
    this.loadTickets();
    this.loadLocation();
    this.loadZones();
  }

  loadVehicle() {
    return api.get(`/vehicle/${this.props.match.params.vehicleId}`)
      .then(this.handleVehicleLoad)
      .catch(this.handleError);
  }

  handleVehicleLoad(res) {
    const vehicle = res.data.data;
    document.title = `${vehicle.registration} | Flamingo Admin`;
    this.setState({ vehicle, loading: false, updating: false, isRefreshing: false })
  }

  loadTasks() {
    return api.get(`/task/active?vehicleId=${this.props.match.params.vehicleId}`)
      .then((res) => this.setState({ tasks: res.data.data }))
      .catch(this.handleError);
  }

  loadTickets() {
    return api.get(`/ticket/active?vehicleId=${this.props.match.params.vehicleId}`)
      .then((res) => this.setState({ tickets: res.data.data }))
      .catch(this.handleError);
  }

  loadZones() {
    return api.get(`/zone/area?active=true&date=${encodeURIComponent(moment().format())}`)
      .then((res) => this.setState({ zones: res.data.data }))
      .catch(console.log);
  }

  /* Location */

  loadLocation() {
    location.refreshLocation();
  }

  locationCheck() {
    const { vehicle, tasks } = this.state;
    const { latitude, longitude } = location.getLastLocation();
    if (vehicle.latitude && vehicle.longitude && latitude && longitude) {
      const distanceAway = distance(vehicle.latitude, vehicle.longitude, latitude, longitude);
      if (distanceAway > 2) {
        if (tasks.length > 0) {
          return window.confirm(`Are you sure you want to perform this action? You are ${distanceAway.toFixed(2)} km away from this vehicle. You won't receive any points for this task.`);
        }
        return window.confirm(`Are you sure you want to perform this action? You are ${distanceAway.toFixed(2)} km away from this vehicle.`);
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  /* API Helpers */

  handleAPI(endpoint, data) {
    this.setState({ updating: true, isRefreshing: true, error: false });
    return api.post(endpoint, data)
      .then((res) => {
        const newState = { updating: false, isRefreshing: false };
        if (res.data.data) {
          newState.vehicle = { ...this.state.vehicle, ...res.data.data };
        }
        this.setState(newState);
      })
      .catch((e) => this.handleError(e, endpoint, data));
  }

  handleError(e, endpoint, data) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    if (error === 'mechanical_lock') {
      return this.confirmMechanicalLock(endpoint, data);
    }
    this.setState({ error, updating: false, isRefreshing: false, loading: false });
  }

  /* Standard Controls */

  handleControl(control) {
    if (control.locationCheck) {
      if (!this.locationCheck()) {
        return;
      }
    }
    if (control.inspectionCheck) {
      if (!this.inspectionCheck()) {
        return;
      }
    }
    return control.function(control.content);
  }

  inspectionCheck() {
    const { vehicle } = this.state;
    const lastInspection = moment().diff(vehicle.lastInspection, 'days');
    if (lastInspection > 21) {
      return window.confirm(`Are you sure you want to perform this action without completing an inspection? Maintenance inspection due ${ moment(vehicle.lastInspection).add(28, 'days').fromNow() }.`);
    } else {
      return true;
    }
  }

  handleRefresh() {
    const vehicleId = this.props.match.params.vehicleId;
    this.setState({ isRefreshing: true, error: false });
    this.loadTasks();
    this.loadTickets();
    return api.post('/vehicle/' + vehicleId)
      .then((res) => this.setState({ vehicle: { ...this.state.vehicle, ...res.data.data }, isRefreshing: false }))
      .catch(this.handleRefreshError);
  }

  handleRefreshError(err) {
    return api.get(`/vehicle/${this.props.match.params.vehicleId}`)
      .then(this.handleVehicleLoad)
      .then(() => this.handleError(err))
      .catch(this.handleError);
  }

  handleToot() {
    const vehicleId = this.props.match.params.vehicleId;
    this.setState({ isTooting: true, error: false });
    api.post('/vehicle/' + vehicleId + '/toot')
      .then((res) => this.setState({ isTooting: false }))
      .catch(this.handleError);
  }

  handleDirections() {
    const { latitude, longitude } = this.state.vehicle;
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    window.open(url, '_blank');
  }

  handleReport() {
    this.setState({ loading: true });
    return api.get(`/vehicle/${this.props.match.params.vehicleId}/rides`)
      .then(this.handleTripReport)
      .catch(this.handleError);
  }

  handleTripReport(res) {
    const trip = res.data.data[0];
    if (trip) {
      return history.push(`/report/trip/${trip.id}`, { trip });
    }
    return history.push(`/incident/new/vehicle/${this.props.match.params.vehicleId}`);
  }

  handleBack() {
    history.goBack();
  }

  handleHelmetLock() {
    const helmetId = this.state.vehicle.helmetLock.id;
    history.push(`/helmet-lock/${helmetId}`);
  }

  handleInspection() {
    const vehicleId = this.props.match.params.vehicleId;
    history.push('/inspection/new/' + vehicleId);
  }

  handleFirmware() {
    const vehicleId = this.props.match.params.vehicleId;
    history.push('/vehicle/' + vehicleId + '/firmware');
  }

  handleBurnout() {
    const vehicleId = this.props.match.params.vehicleId;
    history.push(`/vehicle/${vehicleId}/burnouts`);
  }

  handleIncident() {
    const vehicleId = this.props.match.params.vehicleId;
    history.push('/incident/new/vehicle/' + vehicleId);
  }

  handleLocate() {
    const vehicleId = this.props.match.params.vehicleId;
    history.push(`/vehicle/${vehicleId}/locate`);
  }

  handleSounds() {
    const vehicleId = this.props.match.params.vehicleId;
    history.push(`/vehicle/${vehicleId}/sounds`);
  }

  handleReplace() {
    const vehicleId = this.props.match.params.vehicleId;
    history.push('/vehicle/' + vehicleId + '/replace-iot');
  }

  handleLastPhoto() {
    const vehicleId = this.props.match.params.vehicleId;
    return api.get(`/vehicle/${vehicleId}/last-photo`)
      .then((res) => (/iPhone/.test(navigator.platform) ? window.location = res.data.data.photoUrl : window.open(res.data.data.photoUrl, '_blank')))
      .catch(this.handleError);
  }

  showImei() {
    this.setState({ imeiVisible: true });
  }

  hideImei() {
    this.setState({ imeiVisible: false });
  }

  handleCopyGps() {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = `${this.state.vehicle.latitude}, ${this.state.vehicle.longitude}`;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }

  /* Controls */

  handleLock() {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/vehicle/' + vehicleId + '/lock');
  }

  handleUnlock() {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/vehicle/' + vehicleId + '/unlock');
  }

  handleThrottle(controlType) {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/vehicle/' + vehicleId + '/throttle', { controlType });
  }

  handleSpeedMode(controlType) {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/vehicle/' + vehicleId + '/speed-mode', { controlType });
  }

  handlePower(controlType) {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/vehicle/' + vehicleId + '/power', { controlType });
  }

  handleHeadlight(controlType) {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/vehicle/' + vehicleId + '/headlight', { controlType });
  }

  handleLightFlicker(controlType) {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/vehicle/' + vehicleId + '/light-flicker', { controlType });
  }

  handleSpeedLimit(speedLimit) {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/vehicle/' + vehicleId + '/speed-limit', { speedLimit });
  }

  handleResetSettings() {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/vehicle/' + vehicleId + '/reset-settings');
  }

  handleBeginnerMode() {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/vehicle/' + vehicleId + '/speed-mode', { controlType: 1 })
      .then(() => this.handleAPI('/vehicle/' + vehicleId + '/speed-limit', { speedLimit: 15 }));
  }

  handleShutdown() {
    const res = window.confirm('Are you sure you want to shutdown this vehicle? It must be connected to power to reactivate it.');
    if (!res) return;
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/vehicle/' + vehicleId + '/shutdown');
  }

  handleBatteryCover() {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/vehicle/' + vehicleId + '/battery-cover');
  }

  handleSanitise() {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI('/sanitisation/' + vehicleId);
  }

  confirmMechanicalLock(endpoint, data) {
    const res = window.confirm('The cable lock cannot be detected. Are you sure it has been connected? Press okay to continue if the cable lock is connected.');
    if (res) {
      data.forceMechanicalLock = true;
      return this.handleAPI(endpoint, data);
    } else {
      this.setState({ error: 'mechanical_lock', updating: false, isRefreshing: false, loading: false });
    }
  }

  /* States */

  handleStatus(status) {
    const { tasks } = this.state;
    if (tasks.filter((task) => task.priority > 2 && task.status === 'available').length) {
      alert('As there is a priority task on this vehicle, please remember to take a before and after photo for Slack.');
    }
    const { latitude, longitude, timestamp, accuracy } = location.getLastLocation();
    const vehicleId = this.props.match.params.vehicleId;
    const data = { status };
    if (latitude && longitude && timestamp) {
      data.userLatitude = latitude;
      data.userLongitude = longitude;
      data.userLocationTime = timestamp;
      data.userLocationAccuracy = accuracy;
    }
    return this.handleAPI('/vehicle/' + vehicleId + '/status', data);
  }

  handleEndRide() {
    const res = window.confirm('Are you sure you want to end this ride? Please make sure the vehicle is stationary.');
    if (res) {
      const vehicleId = this.props.match.params.vehicleId;
      return this.handleAPI(`/vehicle/${vehicleId}/end-ride`);
    }
  }

  handleRevertPickup() {
    const description = prompt('Reason for pick up:');
    if (description) {
      const vehicleId = this.props.match.params.vehicleId;
      return this.handleAPI('/vehicle/' + vehicleId + '/revert-pickup', { description });
    }
  }

  handleCancelReservation(status) {
    const res = window.confirm('This vehicle is currently reserved by a rider. Are you sure you want to cancel this reservation?');
    if (res) {
      const vehicleId = this.props.match.params.vehicleId;
      return this.handleAPI(`/vehicle/${vehicleId}/cancel-reservation`);
    }
  }

  handleEndCollection(status) {
    const vehicleId = this.props.match.params.vehicleId;
    return this.handleAPI(`/vehicle/${vehicleId}/end-collection`, { status });
  }

  /* Activation */

  handleActivate() {
    const res = window.confirm('Are you sure you want to activate this IoT? This will take approximately 10 minutes.');
    if (res) {
      const vehicleId = this.props.match.params.vehicleId;
      return this.handleAPI(`/vehicle/${vehicleId}/activate?status=REPAIR`);
    }
  }

  handleSuspend() {
    const res = window.confirm('Are you sure you want to deactivate this IoT?\n\nIf this vehicle was stolen or vandalised has an incident report being created? If the vehicle is missing, have at least 2 staff searched the area for it?');
    if (res) {
      const vehicleId = this.props.match.params.vehicleId;
      return this.handleAPI(`/vehicle/${vehicleId}/suspend?status=IOT`);
    }
  }

  /* Editing Vehicle */

  handleEditQR() {
    const qrCode = prompt("Edit QR Code:");
    if (qrCode) {
      if (qrCode.length !== 6) {
        return alert('Invalid QR Length');
      }
      const vehicleId = this.props.match.params.vehicleId;
      return this.handleAPI('/vehicle/' + vehicleId + '/qr-code', { qrCode });
    }
  }

  handleEditRegistration() {
    const registration = prompt("Edit Registration:");
    if (registration) {
      if (registration.length !== 4) {
        return alert('Invalid Registration Length');
      }
      const vehicleId = this.props.match.params.vehicleId;
      return this.handleAPI('/vehicle/' + vehicleId + '/registration', { registration });
    }
  }

  handleChangeModel() {
    const model = prompt("Change Model:");
    if (model) {
      const vehicleId = this.props.match.params.vehicleId;
      return this.handleAPI('/vehicle/' + vehicleId + '/model', { model });
    }
  }

  handleChangeRegion() {
    const regionId = prompt("Change Region ID:");
    if (regionId) {
      const vehicleId = this.props.match.params.vehicleId;
      return this.handleAPI('/vehicle/' + vehicleId + '/region', { regionId });
    }
  }

  /* View Rendering */

  descriptionForTask(task) {
    switch (task.status) {
      case 'available':
        return task.expiresAt ? `Expires ${ moment(task.expiresAt).fromNow() }${ task.notes ? ` - ${task.notes}` : '.' }` : `Available${ task.notes ? ` - ${task.notes}` : '.' }`;
      case 'reserved':
        return `Reserved by ${ task.assignedTo.firstName } ${ task.assignedTo.lastName }. Expires ${ moment(task.expiresAt).fromNow() }${ task.notes ? `. ${task.notes}` : '.' }`;
      case 'collected':
        return `Collected by ${ task.assignedTo.firstName } ${ task.assignedTo.lastName }${ task.completeBy ? ` - Due ${moment(task.completeBy).fromNow() }` : '' }${ task.notes ? ` - ${task.notes}` : '.' }`;
      default:
        return ``;
    }
  }

  controlsFor(status) {
    switch (status) {
      case 'AVAILABLE':
        return [
          [{ label: 'Transit', function: this.handleStatus, content: 'TRANSIT', locationCheck: true }, { label: 'Demo', function: this.handleStatus, content: 'DEMO', locationCheck: true }],
          [{ label: 'Battery Cover', function: this.handleBatteryCover, model: ['SEGWAY_MAX_PRO', 'SEGWAY_MAX_PLUS', 'SEGWAY_S90L', 'SEGWAY_A200'], locationCheck: true }, { label: 'Helmet', function: this.handleHelmetLock, has: 'helmetLock' }],
          [{ label: 'Lock', function: this.handleLock }, { label: 'Headlight Off', function: this.handleHeadlight, content: false }],
          [{ label: 'Locate', function: this.handleLocate }],
        ];
      case 'UNAVAILABLE':
        return [
          [{ label: 'Transit', function: this.handleStatus, content: 'TRANSIT', locationCheck: true }, { label: 'Available', function: this.handleStatus, content: 'AVAILABLE' }],
          [{ label: 'Lock', function: this.handleLock }, { label: 'Battery Cover', function: this.handleBatteryCover, model: ['SEGWAY_MAX_PRO', 'SEGWAY_MAX_PLUS', 'SEGWAY_S90L', 'SEGWAY_A200'], locationCheck: true }],
          [{ label: 'Locate', function: this.handleLocate }],
        ];
      case 'MAINTENANCE':
        return [
          [{ label: 'Repair', function: this.handleStatus, content: 'REPAIR', locationCheck: true }, { label: 'Available', function: this.handleStatus, content: 'AVAILABLE', inspectionCheck: true }],
          [{ label: 'Lock', function: this.handleLock }, { label: 'Power Off', function: this.handlePower, content: false }],
          [{ label: 'Reset Settings', function: this.handleResetSettings }, { label: 'Firmware', function: this.handleFirmware }],
          [{ label: 'Create Incident', offline: true, function: this.handleIncident }, { label: 'Locate', function: this.handleLocate }],
        ];
      case 'TRANSIT':
        return [
          [{ label: 'Available', function: this.handleStatus, content: 'AVAILABLE', inspectionCheck: true }],
          [{ label: 'Repair', function: this.handleStatus, content: 'REPAIR' }, { label: 'Demo', function: this.handleStatus, content: 'DEMO' }],
          [{ label: 'Inspection', function: this.handleInspection }],
          [{ label: 'Lock', function: this.handleLock }, { label: 'Unlock', function: this.handleUnlock }],
          [{ label: 'Battery Cover', function: this.handleBatteryCover, model: ['SEGWAY_MAX_PRO', 'SEGWAY_MAX_PLUS', 'SEGWAY_S90L', 'SEGWAY_A200'] }, { label: 'Helmet', function: this.handleHelmetLock, has: 'helmetLock' }],
          [{ label: 'Mark as Sanitised', function: this.handleSanitise }],
        ];
      case 'DEMO':
        return [
          [{ label: 'Transit', function: this.handleStatus, content: 'TRANSIT' }, { label: 'Available', function: this.handleStatus, content: 'AVAILABLE', inspectionCheck: true }],
          [{ label: 'Disable Throttle', function: this.handleThrottle, content: false, type: 'SCOOTER' }, { label: 'Enable Throttle', function: this.handleThrottle, content: true, type: 'SCOOTER' }],
          [{ label: 'Beginner Mode', function: this.handleBeginnerMode }, { label: 'Normal Mode', function: this.handleResetSettings }],
          [{ label: 'Headlight On', function: this.handleHeadlight, content: true }, { label: 'Headlight Off', function: this.handleHeadlight, content: false }],
          [{ label: 'Lock', function: this.handleLock }, { label: 'Unlock', function: this.handleUnlock }],
        ];
      case 'INUSE':
        return [
          [{ label: 'End Ride', function: this.handleEndRide }],
          [{ label: 'Disable Throttle', function: this.handleThrottle, content: false, type: 'SCOOTER' }, { label: 'Enable Throttle', function: this.handleThrottle, content: true, type: 'SCOOTER' }],
          [{ label: 'Restrict Speed', function: this.handleSpeedLimit, content: 6 }, { label: 'Reset Speed', function: this.handleResetSettings }],
          [{ label: 'Unlock', function: this.handleUnlock }, { label: 'Helmet', function: this.handleHelmetLock, has: 'helmetLock' }],
        ];
      case 'REPAIR':
        return [
          [{ label: 'End Repair', function: this.handleStatus, content: 'TRANSIT' }],
          [{ label: 'Flag for Pick Up', function: this.handleRevertPickup }],
          [{ label: 'Inspection', function: this.handleInspection }, { label: 'Firmware', function: this.handleFirmware }],
          [{ label: 'Lock', function: this.handleLock }, { label: 'Unlock', function: this.handleUnlock }],
          [{ label: 'Power On', function: this.handlePower, content: true }, { label: 'Power Off', function: this.handlePower, content: false }, { label: 'Shutdown', function: this.handleShutdown, content: false }],
          [{ label: 'Headlight On', function: this.handleHeadlight, content: true }, { label: 'Headlight Off', function: this.handleHeadlight, content: false }],
          [{ label: 'Light Flicker On', function: this.handleLightFlicker, content: true, type: 'SCOOTER' }, { label: 'Light Flicker Off', function: this.handleLightFlicker, content: false, type: 'SCOOTER' }],
          [{ label: 'Speed Mode Normal', function: this.handleSpeedMode, content: 2 }, { label: 'Speed Mode High', function: this.handleSpeedMode, content: 3 }],
          [{ label: 'Update Sounds', function: this.handleSounds, model: ['SEGWAY_MAX_PLUS'] }],
          [{ label: 'Edit QR', function: this.handleEditQR }, { label: 'Edit Registration', function: this.handleEditRegistration }],
          [{ label: 'Change Model', function: this.handleChangeModel }, { label: 'Change Region', function: this.handleChangeRegion }],
          [{ label: 'IoT Mode', function: this.handleSuspend }, { label: 'Replace IoT', function: this.handleReplace }],
          [{ label: 'Battery Cover', function: this.handleBatteryCover, model: ['SEGWAY_MAX_PRO', 'SEGWAY_MAX_PLUS', 'SEGWAY_S90L', 'SEGWAY_A200'] }, { label: 'Helmet', function: this.handleHelmetLock, has: 'helmetLock' }, { label: 'Reset Settings', function: this.handleResetSettings }],
          [{ label: 'Burnout Analysis', function: this.handleBurnout }, { label: 'Create Incident', offline: true, function: this.handleIncident }],
        ];
      case 'RESERVED':
        return [
          [{ label: 'Cancel Reservation', function: this.handleCancelReservation }],
        ];
      case 'CHARGING':
        return [
          [{ label: 'Transit', function: this.handleEndCollection, content: 'TRANSIT' }, { label: 'Available', function: this.handleEndCollection, content: 'AVAILABLE' }],
        ];
      case 'IOT':
        return [
          [{ label: 'Activate', function: this.handleActivate }, { label: 'Mark Offline', function: this.handleStatus, content: 'OFFLINE' }],
          [{ label: 'Replace IoT', function: this.handleReplace }],
        ];
      case 'OFFLINE':
        return [
          [{ label: 'IoT Mode', function: this.handleStatus, content: 'IOT' }, { label: 'Replace IoT', function: this.handleReplace }],
          [{ label: 'Create Incident', offline: true, function: this.handleIncident }],
        ];
      default:
        return [];
    }
  }

  renderControls(vehicle) {
    const controls = this.controlsFor(vehicle.status);
    return (
      <div className="fm-vehicle-controls">
        {
          controls.map((group, i) => {
            return (
              <div className="fm-vehicle-controls-group" key={i}>
                {
                  group.map((control, ii) => {
                    if (control.model && !control.model.includes(vehicle.model)) {
                      return null;
                    }
                    if (control.type && control.type !== vehicle.type) {
                      return null;
                    }
                    if (control.has && !vehicle[control.has]) {
                      return null;
                    }
                    if (control.offline && vehicle.online) {
                      return null;
                    }
                    return (
                      <button className="fm-vehicle-controls-button" key={ii} onClick={() => this.handleControl(control)}>
                        { control.label }
                      </button>
                    );
                  })
                }
              </div>
            );
          })
        }
      </div>
    );
  }

  renderTask(task, i) {
    return (
      <Link className="fm-vehicle-task-item" key={i} to={`/task/${task.id}`}>
        <div className="fm-vehicle-task-item-header">
          <p className="fm-vehicle-task-item-type" style={{ backgroundColor: colors.task[task.type] }}>{ task.type.toUpperCase() }</p>
          <p className="fm-vehicle-task-item-created">{ moment(task.createdAt).fromNow() }</p>
          <div className="fm-vehicle-task-item-status" style={{ backgroundColor: colors.taskStatus[task.status] }}></div>
        </div>
        <p className="fm-vehicle-task-item-details">{ this.descriptionForTask(task) }</p>
      </Link>
    );
  }

  renderTicket(ticket, i) {
    return (
      <Link className="fm-vehicle-task-item" key={i} to={`/ticket/${ticket.id}`}>
        <div className="fm-vehicle-task-item-header">
          <p className="fm-vehicle-task-item-type" style={{ backgroundColor: colors.vehicleTicket[ticket.type] }}>{ ticket.type.toUpperCase() }</p>
          <p className="fm-vehicle-task-item-created">{ moment(ticket.createdAt).fromNow() }</p>
        </div>
        <p className="fm-vehicle-task-item-details">{ ticket.description }</p>
      </Link>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    const { vehicle, isRefreshing, isTooting, tickets, tasks, zones, imeiVisible } = this.state;
    const mapOptions = { center: { lat: vehicle.latitude, lng: vehicle.longitude }, zoom: 14 };
    const lastInspection = moment().diff(this.state.vehicle.lastInspection, 'days');
    const lastActivity = moment().diff(this.state.vehicle.lastActivity, 'hours');
    return (
      <div className="fm-vehicle">
        <div className="fm-vehicle-navigation">
          <div className="fm-vehicle-navigation-left">
            <button className="fm-vehicle-navigation-action" onClick={this.handleBack}>
              <Icon icon="chevron-left" />
            </button>
            <p className="fm-vehicle-navigation-rego">{ vehicle.registration }</p>
          </div>
          <div className="fm-vehicle-navigation-right">
            <button className="fm-vehicle-navigation-action" onClick={this.handleReport}>
              <Icon icon="exclamation-triangle" />
            </button>
            <button className="fm-vehicle-navigation-action" onClick={this.handleDirections}>
              <Icon icon="directions" />
            </button>
            <button className="fm-vehicle-navigation-action" onClick={this.handleToot}>
              { isTooting ? <Loading /> : <Icon icon="volume-up" /> }
            </button>
            <button className="fm-vehicle-navigation-action" onClick={this.handleRefresh}>
              { isRefreshing ? <Loading /> : <Icon icon="sync-alt" /> }
            </button>
          </div>
        </div>
        <div className="fm-vehicle-content">
          <div className="fm-vehicle-map">
            <Map options={mapOptions} google={window.google}>
              { zones.map((zone, i) => <Polygon key={i} paths={ zone.polygon.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeWeight={0} fillColor={colors.zone[zone.type]} fillOpacity={0.2} />) }
              { vehicle.hdop && <Circle position={{ lat: vehicle.latitude, lng: vehicle.longitude }} radius={vehicle.hdop * 10} strokeWeight={0} fillColor={'#ff206e'} fillOpacity={0.33} /> }
              <Marker position={{ lat: vehicle.latitude, lng: vehicle.longitude }} title={vehicle.registration} />
            </Map>
          </div>
          { vehicle.online === false &&
            <div className="fm-vehicle-banner fm-vehicle-banner-critical">Warning: This vehicle is no longer online.</div>
          }
          { (lastInspection > 15) &&
            <Link className="fm-vehicle-banner fm-vehicle-banner-inspection" to={`/inspection/new/${vehicle.id}`}>Maintenance inspection due { moment(vehicle.lastInspection).add(28, 'days').fromNow() }.</Link>
          }
          { vehicle.fallenSince &&
            <div className="fm-vehicle-banner fm-vehicle-banner-fallen">Vehicle fallen { moment(vehicle.fallenSince).fromNow() }.</div>
          }
          { (['AVAILABLE', 'TRANSIT', 'UNAVAILABLE'].includes(vehicle.status) && lastActivity > 24) &&
            <div className="fm-vehicle-banner fm-vehicle-banner-info">No trips for { lastActivity } hours.</div>
          }
          { (vehicle.status === 'AVAILABLE' && vehicle.type === 'BIKE' && vehicle.mechanicalLocked === false) &&
            <div className="fm-vehicle-banner fm-vehicle-banner-info">Cable lock not connected.</div>
          }
          { (vehicle.status === 'RESERVED' && vehicle.reservedUntil) &&
            <div className="fm-vehicle-banner fm-vehicle-banner-reserved">Vehicle reserved for { moment(vehicle.reservedUntil).fromNow(true) }.</div>
          }
          { (vehicle.status === 'INUSE' && vehicle.currentRideId) &&
            <Link className="fm-vehicle-banner fm-vehicle-banner-inuse" to={`/trip/${vehicle.currentRideId}`}>INUSE - Tap to view</Link>
          }
          <div className="fm-vehicle-details">
            <div className="fm-vehicle-details-left">
              <p className="fm-vehicle-details-qr">{ vehicle.qrCode ? vehicle.qrCode.slice(0, 3) + '-' + vehicle.qrCode.slice(3) : 'XXX-XXX' }</p>
              <p className="fm-vehicle-details-model" onMouseEnter={this.showImei} onMouseLeave={this.hideImei}>{ imeiVisible ? vehicle.iotCode : vehicle.model }</p>
            </div>
            <div className="fm-vehicle-details-right">
              <p className="fm-vehicle-details-status" style={{ backgroundColor: colors.status[vehicle.status] }} title={`${vehicle.status} - Click for photo`} onClick={this.handleLastPhoto}>{ vehicle.status }</p>
              <p className="fm-vehicle-details-updated">{ vehicle.statusTime ? moment(vehicle.statusTime).fromNow() : '...' } ({parseInt(vehicle.batteryPercent * 100)}%)</p>
            </div>
          </div>
          <div className="fm-vehicle-details-controls">
            { this.renderControls(vehicle) }
          </div>
          <div className="fm-vehicle-section-header">
            <p className="fm-vehicle-section-title">Tasks</p>
            <Link to={`/task/new/${vehicle.id}`} className="fm-vehicle-section-button">
              <Icon icon="plus" />
            </Link>
          </div>
          <div className="fm-vehicle-tasks">
            { tasks.map(this.renderTask) }
            { tasks.length === 0 && <p className="fm-vehicle-section-empty">No active tasks.</p> }
          </div>
          <div className="fm-vehicle-section-header">
            <p className="fm-vehicle-section-title">Tickets</p>
            <Link to={`/ticket/new/${vehicle.id}`} className="fm-vehicle-section-button">
              <Icon icon="plus" />
            </Link>
          </div>
          <div className="fm-vehicle-tasks">
            { tickets.map(this.renderTicket) }
            { tickets.length === 0 && <p className="fm-vehicle-section-empty">No active tickets.</p> }
          </div>
          <div className="fm-vehicle-section-header">
            <p className="fm-vehicle-section-title">Status</p>
          </div>
          <div className="fm-vehicle-status">
            <div className="fm-vehicle-status-item">
              <div className="fm-vehicle-status-item-icon">
                <Icon icon={ (vehicle.type === 'BIKE' ? (vehicle.locked && vehicle.mechanicalLocked) : vehicle.locked) ? 'lock' : 'lock-open' } />
              </div>
              <p className="fm-vehicle-status-item-text">{ vehicle.type === 'BIKE' ? `${ vehicle.locked ? 'Locked' : 'Unlocked' } (Cable ${ vehicle.mechanicalLocked ? 'Locked' : 'Unlocked' })` : (vehicle.locked ? 'Locked' : 'Unlocked') }</p>
            </div>
            <div className="fm-vehicle-status-item">
              <div className="fm-vehicle-status-item-icon">
                <Icon icon={ vehicle.charging ? 'bolt' : 'plug' } />
              </div>
              <p className="fm-vehicle-status-item-text">{ vehicle.charging ? 'Charging' : 'Not Charging' }</p>
            </div>
            <div className="fm-vehicle-status-item">
              <div className="fm-vehicle-status-item-icon">
                <Icon icon="battery-three-quarters" />
              </div>
              <p className="fm-vehicle-status-item-text">{ parseInt(vehicle.batteryPercent * 100) }% / { parseInt(vehicle.remainingRange / 100) }km</p>
            </div>
            <div className="fm-vehicle-status-item">
              <div className="fm-vehicle-status-item-icon">
                <Icon icon="tachometer-alt" />
              </div>
              <p className="fm-vehicle-status-item-text">{ vehicle.speed }kmh / { vehicle.speedLimit }kmh</p>
            </div>
            <div className="fm-vehicle-status-item">
              <div className="fm-vehicle-status-item-icon">
                <Icon icon="broadcast-tower" />
              </div>
              <p className="fm-vehicle-status-item-text">{ moment(vehicle.statusTime).format('H:mm D/M/YY') }</p>
            </div>
            <div className="fm-vehicle-status-item" onClick={this.handleCopyGps}>
              <div className="fm-vehicle-status-item-icon">
                <Icon icon="satellite" />
              </div>
              <p className="fm-vehicle-status-item-text">{ moment(vehicle.gpsTime).format('H:mm D/M/YY') }</p>
            </div>
          </div>
          <VehicleTabs id={vehicle.id} />
        </div>
        { /* ------ Loading Pop Up ------ */ }
        <PopUp active={this.state.updating} title="Updating" description="Please wait while the vehicle is updated." onDismiss={() => this.setState({ updating: false })}>
          <Loading />
        </PopUp>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default Vehicle;
