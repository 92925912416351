import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import history from 'helpers/history';

const categories = [
  { icon: 'parking', category: 'parking', title: 'Parking', description: 'Blocking Pathway, Not in Photo, etc.' },
  { icon: 'biking', category: 'riding', title: 'Riding', description: 'Double Riding, Dangerous Riding, etc.' },
  { icon: 'map', category: 'zone', title: 'Zone', description: 'No Parking Zone, Service Area, etc.' },
  { icon: 'exclamation-triangle', category: 'incident', title: 'Incident', description: 'Vandalism, Accident, etc.' },
];

class ReportTrip extends Component {
  constructor(props) {
    super(props);
    const preloadedTrip = props.location.state ? props.location.state.trip : false;
    this.state = { trip: preloadedTrip || {} };

    this.renderCategory = this.renderCategory.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.title = 'Report Trip | Flamingo Admin';
  }

  handleClick(item) {
    switch (item.category) {
      case 'incident':
        return history.push(`/incident/new/trip/${this.props.match.params.tripId}`);
      default:
        return history.push(`/report/trip/${this.props.match.params.tripId}/${item.category}`);
    }
  }

  renderCategory(category, i) {
    return (
      <div className="fm-report-trip-category" key={i} onClick={() => this.handleClick(category)}>
        <div className="fm-report-trip-category-card">
          <div className="fm-report-trip-category-icon">
            <Icon icon={category.icon} />
          </div>
          <div className="fm-report-trip-category-text">
            <p className="fm-report-trip-category-title">{ category.title }</p>
            <p className="fm-report-trip-category-description">{ category.description }</p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { trip } = this.state;
    return (
      <div className="fm-report-trip">
        <NavigationBar title="Trip Report" showBack={true} />
        <div className="fm-report-trip-content">
          { categories.map(this.renderCategory) }
        </div>
        {
          trip.parkingPhotoUrl &&
          <div className="fm-report-trip-parking">
            <img src={trip.parkingPhotoUrl} alt="Last Trip Parking" className="fm-report-trip-parking-img" />
          </div>
        }
      </div>
    );
  }
}

export default ReportTrip;
