import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Icon from 'components/common/icon';
import api from 'helpers/api';
import colors from 'helpers/colors';

class Statistics extends Component {
  constructor() {
    super();
    this.state = { regions: [], loading: true };

    this.loadStats = this.loadStats.bind(this);
    this.handleError = this.handleError.bind(this);

    this.renderRegion = this.renderRegion.bind(this);
    this.renderSum = this.renderSum.bind(this);
    this.renderStatuses = this.renderStatuses.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
  }

  componentDidMount() {
    document.title = 'Statistics | Flamingo Admin';
    this.loadStats();
  }

  loadStats() {
    this.setState({ loading: true });
    return api.get('/statistics/current')
      .then((res) => this.setState({ regions: res.data.data.regions, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  renderActive() {
    const active = this.state.current.active;
    const maxActive = Math.max.apply(Math, active.map((a) => a.totalActive));
    return (
      <div className="fm-statistics-active-hours">
        {
          active.map((activeHour, i) => {
            return (
              <div className="fm-statistics-active" key={i}>
                <div className="fm-statistics-active-bar-holder">
                  <div className="fm-statistics-active-bar" title={activeHour.totalActive} style={{ height: (((activeHour.totalActive / maxActive) * 100) + '%') }}></div>
                </div>
                <p className="fm-statistics-active-value">{ activeHour.totalActive || 0 }</p>
                <p className="fm-statistics-active-date">{ moment(activeHour.activeAt).format('ha') }</p>
              </div>
            );
          })
        }
      </div>
    );
  }

  renderDailyActive() {
    const dailyActive = this.state.current.dailyActive;
    const maxDailyActive = Math.max.apply(Math, dailyActive.map((a) => a.maxActive));
    return (
      <div className="fm-statistics-active-hours">
        {
          dailyActive.map((activeDay, i) => {
            return (
              <div className="fm-statistics-active" key={i}>
                <div className="fm-statistics-active-bar-holder">
                  <div className="fm-statistics-active-bar" title={activeDay.maxActive} style={{ height: (((activeDay.maxActive / maxDailyActive) * 100) + '%') }}></div>
                </div>
                <p className="fm-statistics-active-value">{ activeDay.maxActive || 0 }</p>
                <p className="fm-statistics-active-date">{ moment(activeDay.activeDay).format('D/M') }</p>
              </div>
            );
          })
        }
      </div>
    );
  }

  renderRatings() {
    const ratings = this.state.current.ratings;
    const minRating = Math.min.apply(Math, ratings.map((a) => a.avgRating));
    const maxRating = Math.max.apply(Math, ratings.map((a) => a.avgRating)) - minRating;
    return (
      <div className="fm-statistics-active-hours">
        {
          ratings.map((ratingHour, i) => {
            return (
              <div className="fm-statistics-active" key={i}>
                <div className="fm-statistics-active-bar-holder">
                  <div className="fm-statistics-active-bar" title={ratingHour.avgRating} style={{ height: ((((ratingHour.avgRating - minRating) / maxRating) * 100) + '%') }}></div>
                </div>
                <p className="fm-statistics-active-value">{ (ratingHour.avgRating || 0).toFixed(2) }</p>
                <p className="fm-statistics-active-date">{ ratingHour.ratingWeek }</p>
              </div>
            );
          })
        }
      </div>
    );
  }

  renderAvailable(item, i, max, dateFormat) {
    return (
      <div className="fm-statistics-region-chart-column" key={i}>
        <div className="fm-statistics-region-chart-holder">
          { item.values.map((value, ii) => <div className="fm-statistics-region-chart-bar" key={ii} title={value.maxActive} style={{ height: (((value.maxActive / max) * 100) + '%'), backgroundColor: colors.statisticTypes[value.type] }}>{ value.type.charAt(0) }</div>) }
        </div>
        <div className="fm-statistics-region-chart-values">
          { item.values.map((value, ii) => <p className="fm-statistics-region-chart-value" key={ii}>{ value.maxActive }</p>) }
        </div>
        <p className="fm-statistics-region-chart-title">{ moment(item.activeTime || item.activeDate).format(dateFormat) }</p>
      </div>
    );
  }

  renderRating(item, i, min, max) {
    return (
      <div className="fm-statistics-region-chart-column" key={i}>
        <div className="fm-statistics-region-chart-holder">
          <div className="fm-statistics-region-chart-bar" title={item.avgRating} style={{ height: ((((item.avgRating - min) / max) * 100) + '%') }}></div>
        </div>
        <div className="fm-statistics-region-chart-values">
          <p className="fm-statistics-region-chart-value">{ item.avgRating.toFixed(2) }</p>
        </div>
        <p className="fm-statistics-region-chart-title">{ (item.ratingWeek || '').slice(-3) }</p>
      </div>
    );
  }

  renderStatus(status, i, total) {
    return (
      <div className="fm-statistics-region-statuses-item" key={i} style={{ backgroundColor: colors.status[status] }}>
        <p className="fm-statistics-region-statuses-item-total">{ total }</p>
        <p className="fm-statistics-region-statuses-item-label">{ status }</p>
      </div>
    );
  }

  renderStatuses(type, i, statuses) {
    const statusKeys = Object.keys(statuses);
    return (
      <div className="fm-statistics-region-statuses" key={i}>
        <p className="fm-statistics-subtitle">{ type } Statuses</p>
        <div className="fm-statistics-region-statuses-items">
          { statusKeys.map((status, i) => this.renderStatus(status, i, statuses[status])) }
        </div>
      </div>
    );
  }

  renderSum(type, i, statuses, cap = 0) {
    const inUseStatuses = ['INUSE'];
    const hireableStatuses = ['AVAILABLE', 'INUSE'];
    const onStreetStatuses = ['AVAILABLE', 'INUSE', 'UNAVAILABLE', 'MAINTENANCE'];

    const inUseTotal = inUseStatuses.reduce((total, status) => total + (statuses[status] || 0), 0);
    const hireableTotal = hireableStatuses.reduce((total, status) => total + (statuses[status] || 0), 0);
    const onStreetTotal = onStreetStatuses.reduce((total, status) => total + (statuses[status] || 0), 0);

    return (
      <div className="fm-statistics-region-sums-item" key={i}>
        <p className="fm-statistics-region-sums-item-type">{ type }s</p>
        <div className="fm-statistics-region-sums-item-values">
          <p className="fm-statistics-region-sums-item-label fm-statistics-region-sums-item-label-inuse">{ inUseTotal }</p>
          <p className="fm-statistics-region-sums-item-slash">/</p>
          <p className="fm-statistics-region-sums-item-label fm-statistics-region-sums-item-label-hireable">{ hireableTotal }</p>
          <p className="fm-statistics-region-sums-item-slash">/</p>
          <p className="fm-statistics-region-sums-item-label fm-statistics-region-sums-item-label-onstreet">{ onStreetTotal }</p>
          <p className="fm-statistics-region-sums-item-slash">/</p>
          <p className="fm-statistics-region-sums-item-label fm-statistics-region-sums-item-label-cap">{ cap }</p>
        </div>
      </div>
    );
  }

  renderRegion(region, i) {
    const types = Object.keys(region.statuses);

    const minRating = Math.min.apply(Math, region.ratings.map((a) => a.avgRating));
    const maxRating = Math.max.apply(Math, region.ratings.map((a) => a.avgRating)) - minRating;
    const maxDailyAvailable = Math.max.apply(Math, region.dailyActive.map((d) => Math.max.apply(Math, d.values.map((v) => v.maxActive))));
    const maxHourlyAvailable = Math.max.apply(Math, region.hourlyActive.map((d) => Math.max.apply(Math, d.values.map((v) => v.maxActive))));

    return (
      <div className="fm-statistics-region" key={i}>
        <p className="fm-statistics-title">{ region.name }</p>
        <div className="fm-statistics-region-sums">
          <div className="fm-statistics-region-sums-items">
            { types.map((type, i) => this.renderSum(type, i, region.statuses[type], region.cap[type])) }
          </div>
          <div className="fm-statistics-region-sums-footer">
            <p className="fm-statistics-region-sums-battery"><Icon icon="battery-half" /> { region.averageBattery || 100 }% Avg</p>
            <p className="fm-statistics-region-sums-note">Total <span className="fm-statistics-region-sums-note-inuse">In Use</span>, <span className="fm-statistics-region-sums-note-hireable">Hireable</span>, <span className="fm-statistics-region-sums-note-onstreet">On Street</span>, <span className="fm-statistics-region-sums-note-cap">Cap</span>.</p>
          </div>
        </div>
        { types.map((type, i) => this.renderStatuses(type, i, region.statuses[type], region.cap[type])) }
        <div className="fm-statistics-region-trips">
          <p className="fm-statistics-subtitle">Total Trips</p>
          <div className="fm-statistics-region-trips-split">
            <p className="fm-statistics-region-trips-text">Today</p>
            <p className="fm-statistics-region-trips-text">{ region.dayTrips || 0 }</p>
          </div>
        </div>
        <div className="fm-statistics-region-hourly">
          <p className="fm-statistics-subtitle">Vehicles Available Hourly</p>
          <div className="fm-statistics-region-chart">
            { region.hourlyActive.reverse().map((hour, i) => this.renderAvailable(hour, i, maxHourlyAvailable, 'ha')) }
          </div>
        </div>
        <div className="fm-statistics-region-daily">
          <p className="fm-statistics-subtitle">Vehicles Available Daily</p>
          <div className="fm-statistics-region-chart">
            { region.dailyActive.reverse().map((day, i) => this.renderAvailable(day, i, maxDailyAvailable, 'D/M')) }
          </div>
        </div>
        <div className="fm-statistics-region-ratings">
          <p className="fm-statistics-subtitle">Average Ratings</p>
          <div className="fm-statistics-region-chart">
            { region.ratings.reverse().map((week, i) => this.renderRating(week, i, minRating, maxRating)) }
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loading, error, regions } = this.state;
    const refreshAction = { onClick: this.loadStats, icon: 'sync-alt' };

    return (
      <div className="fm-statistics">
        <NavigationBar title="Statistics" loading={loading} rightActions={[refreshAction]} />
        { regions.map(this.renderRegion) }
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default Statistics;
