import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Marker } from 'components/common/map';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import geocoder from 'helpers/geocoder';
import api from 'helpers/api';

const types = [{ value: 'incident', label: 'Incident' }, { value: 'nearMiss', label: 'Near Miss' }, { value: 'vandalism', label: 'Vandalism' }, { value: 'theft', label: 'Theft' }];
const severities = [{ value: 'minor', label: 'Minor' }, { value: 'moderate', label: 'Moderate' }, { value: 'significant', label: 'Significant' }, { value: 'major', label: 'Major' }, { value: 'severe', label: 'Severe' }];

class IncidentNew extends Component {
  constructor(props) {
    super(props);
    this.state = { incident: { maintenanceFlagged: false, insightsVisible: false }, regions: [], now: moment().format('YYYY-MM-DDTHH:mm'), loading: true, error: false };

    this.loadData = this.loadData.bind(this);
    this.loadLinked = this.loadLinked.bind(this);
    this.parseLinked = this.parseLinked.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  componentDidMount() {
    document.title = 'New Incident | Flamingo Admin';
    this.loadData();
  }

  // Networking

  loadData() {
    const { type, typeId } = this.props.match.params;
    return api.get('/region/all')
      .then((res) => this.setState({ regions: res.data.data }))
      .then(() => this.loadLinked(type, typeId))
      .then((data) => this.parseLinked(type, data))
      .then((incident) => this.setState({ incident, loading: false }))
      .catch(this.handleError);
  }

  loadLinked(type, typeId) {
    if (['user', 'vehicle', 'trip'].includes(type) && typeId) {
      return api.get(`/${type}/${typeId}`)
        .then((res) => Promise.resolve(res.data.data));
    }
    return {};
  }

  setLocation(latitude, longitude, isInitialLocation = false) {
    if (isInitialLocation) {
      this.setState({ initialLocation: { latitude, longitude } });
    }
    return geocoder(latitude, longitude)
      .then((location) => this.setState({ incident: { ...this.state.incident, location } }))
      .catch(this.handleError);
  }

  parseLinked(type, data) {
    switch (type) {
      case 'user':
        return {
          userId: data.id,
          name: `${data.firstName} ${data.lastName}`,
          phone: data.phone,
          email: data.email,
          maintenanceFlagged: false,
          insightsVisible: false,
        };
      case 'vehicle':
        this.setLocation(data.latitude, data.longitude, true);
        return {
          vehicleId: data.id,
          vehicleRegistration: data.registration,
          regionId: data.regionId,
          startTime: moment(data.lastActivity).format('YYYY-MM-DDTHH:mm'),
          endTime: moment(data.gpsTime).format('YYYY-MM-DDTHH:mm'),
          maintenanceFlagged: false,
          insightsVisible: false,
          latitude: data.latitude,
          longitude: data.longitude,
        };
      case 'trip':
        this.setLocation(data.endLatitude || data.startLatitude, data.endLongitude || data.startLongitude, true);
        return {
          tripId: data.id,
          userId: data.user.id,
          vehicleId: data.vehicle.id,
          vehicleRegistration: data.vehicle.registration,
          regionId: data.regionId,
          name: `${data.user.firstName} ${data.user.lastName}`,
          phone: data.user.phone,
          email: data.user.email,
          startTime: moment(data.startTime).format('YYYY-MM-DDTHH:mm'),
          endTime: moment(data.endTime).format('YYYY-MM-DDTHH:mm'),
          maintenanceFlagged: false,
          insightsVisible: false,
          latitude: data.endLatitude || data.startLatitude,
          longitude: data.endLongitude || data.startLongitude,
        };
      default:
        return {
          maintenanceFlagged: false,
          insightsVisible: false,
        };
    }
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  // Handlers

  onChange(e) {
    const incident = this.state.incident;
    incident[e.target.getAttribute('okey')] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ incident, error: false })
  }

  handleMapClick(latitude, longitude) {
    const { incident } = this.state;
    incident.latitude = latitude;
    incident.longitude = longitude;
    this.setState({ incident }, () => this.setLocation(latitude, longitude));
  }

  handleCreate() {
    const { incident } = this.state;
    const requiredKeys = ['type', 'regionId', 'details', 'startTime', 'endTime'];
    const missingValue = requiredKeys.find((key) => incident[key] === undefined || incident[key].length === 0);
    if (missingValue) {
      return this.setState({ error: `${missingValue} is required` });
    }
    incident.startTime = moment(incident.startTime).utc().format();
    incident.endTime = moment(incident.endTime).utc().format();
    this.setState({ error: false, loading: true });
    return api.post('/incident', incident)
      .then((res) => history.push(`/incident/${res.data.data.id}`))
      .catch(this.handleError);
  }

  severityTypes() {
    alert('Minor\nInjuries or ailments not requiring medical treatment apart from minor first aid.\n\nModerate\nMinor injury or medical treatment case requiring A&E.\n\nSignificant\nSerious injury causing hospitalisation or multiple medical treatment cases.\n\nMajor\nLife threatening injury or multiple serious injuries causing hospitalisation.\n\nSevere\nDeath or multiple life threatening injuries.');
  }

  // Renders

  render() {
    const { loading, incident, regions, now, error, initialLocation } = this.state;
    const subtitle = this.props.match.params.type ? `From ${this.props.match.params.type}` : undefined;

    const helpAction = { onClick: this.severityTypes, icon: 'info-circle' };

    const hasLocation = incident.latitude && incident.longitude;
    const mapOptions = { center: initialLocation ? { lat: initialLocation.latitude, lng: initialLocation.longitude } : undefined, zoom: 15 };

    return (
      <div className="fm-incident-new">
        <NavigationBar title="New Incident" subtitle={subtitle} loading={loading} rightActions={[helpAction]} showBack={true} />
        <div className="fm-incident-new-content">
          <div className="fm-incident-new-input-row">
            <div className="fm-incident-new-input">
              <p className="fm-input-label">Type</p>
              <select value={incident.type} disabled={loading} onChange={this.onChange} okey="type" defaultValue="-1" className="fm-input fm-input-select">
                <option disabled value="-1">Select an option</option>
                { types.map((item, i) => <option key={i} value={item.value}>{item.label}</option>) }
              </select>
            </div>
            {
              ['incident','nearMiss'].includes(incident.type) &&
              <div className="fm-incident-new-input">
                <p className="fm-input-label">Severity</p>
                <select value={incident.severity} disabled={loading} onChange={this.onChange} okey="severity" defaultValue="-1" className="fm-input fm-input-select">
                  <option disabled value="-1">Select an option</option>
                  { severities.map((item, i) => <option key={i} value={item.value}>{item.label}</option>) }
                </select>
              </div>
            }
          </div>
          <div className="fm-incident-new-input-row">
            <div className="fm-incident-new-input">
              <p className="fm-input-label">City</p>
              <select value={incident.regionId} disabled={loading || incident.vehicleId} onChange={this.onChange} okey="regionId" defaultValue="-1" className="fm-input fm-input-select">
                <option disabled value="-1">Select an option</option>
                { regions.map((item, i) => <option key={i} value={item.id}>{item.name}</option>) }
              </select>
            </div>
            <div className="fm-incident-new-input">
              <p className="fm-input-label">Vehicle Registration</p>
              <input value={incident.vehicleRegistration || ''} onChange={this.onChange} okey="vehicleRegistration" className="fm-input" disabled={loading || incident.vehicleId} />
            </div>
          </div>
          <div className="fm-incident-new-input">
            <p className="fm-input-label">Name</p>
            <input value={incident.name || ''} onChange={this.onChange} okey="name" className="fm-input" disabled={loading || incident.userId} />
          </div>
          <div className="fm-incident-new-input-row">
            <div className="fm-incident-new-input">
              <p className="fm-input-label">Email</p>
              <input value={incident.email || ''} onChange={this.onChange} okey="email" className="fm-input" disabled={loading || incident.userId} />
            </div>
            <div className="fm-incident-new-input">
              <p className="fm-input-label">Phone</p>
              <input value={incident.phone || ''} onChange={this.onChange} okey="phone" className="fm-input" disabled={loading || incident.userId} />
            </div>
          </div>
          <div className="fm-incident-new-input-row">
            <div className="fm-incident-new-input">
              <p className="fm-input-label">Start Time</p>
              <input type="datetime-local" value={incident.startTime || now} onChange={this.onChange} okey="startTime" className="fm-input" disabled={loading} />
            </div>
            <div className="fm-incident-new-input">
              <p className="fm-input-label">End Time</p>
              <input type="datetime-local" value={incident.endTime || now} onChange={this.onChange} okey="endTime" className="fm-input" disabled={loading} />
            </div>
          </div>
          <div className="fm-incident-new-input">
            <p className="fm-input-label">Location</p>
            <input value={incident.location || ''} onChange={this.onChange} okey="location" className="fm-input" disabled={loading} />
          </div>
          <div className="fm-incident-new-map">
            <Map google={window.google} onClick={this.handleMapClick} options={mapOptions}>
              { hasLocation && <Marker position={{ lat: incident.latitude, lng: incident.longitude }} title="Hub Location" /> }
            </Map>
          </div>
          {
            ['incident','nearMiss'].includes(incident.type) &&
            <div className="fm-incident-new-input">
              <p className="fm-input-label">Weather</p>
              <input value={incident.weather || ''} onChange={this.onChange} okey="weather" className="fm-input" disabled={loading} />
            </div>
          }
          <div className="fm-incident-new-input">
            <p className="fm-input-label">Initial Details</p>
            <textarea className="fm-input fm-input-textarea fm-incident-new-textarea" value={incident.details} onChange={this.onChange} okey="details" disabled={loading}></textarea>
          </div>
          <div className="fm-incident-new-input-row">
            <div className="fm-incident-new-input">
              <label className="fm-input-label">Flagged for Maintenance</label>
              <label className="fm-input-checkbox-container">
                <input className="fm-input fm-input-checkbox" disabled={loading} type="checkbox" checked={incident.maintenanceFlagged} okey="maintenanceFlagged" onChange={this.onChange} />
                <span className="fm-input-checkbox-checkmark"></span>
              </label>
            </div>
            <div className="fm-incident-new-input">
              <label className="fm-input-label">Visible on Insights</label>
              <label className="fm-input-checkbox-container">
                <input className="fm-input fm-input-checkbox" disabled={loading} type="checkbox" checked={incident.insightsVisible} okey="insightsVisible" onChange={this.onChange} />
                <span className="fm-input-checkbox-checkmark"></span>
              </label>
            </div>
          </div>
          <button className="fm-incident-new-button" onClick={this.handleCreate} disabled={loading}>{ loading ? 'Loading...' : 'Create Incident' }</button>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default IncidentNew;
