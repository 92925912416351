import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class InstructionGroup extends Component {
  constructor(props) {
    super(props);
    const preloadedGroup = props.location.state ? props.location.state.group : false;
    this.state = { group: preloadedGroup || {}, loading: true, error: false };

    this.loadGroup = this.loadGroup.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderInstruction = this.renderInstruction.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    this.loadGroup();
  }

  /* NETWORKING */

  loadGroup() {
    this.setState({ loading: true });
    api.get(`/instruction/group/${this.props.match.params.instructionGroupId}`)
      .then((res) => this.setState({ group: res.data.data, loading: false }, () => { document.title = `${res.data.data.name} | Flamingo Admin` }))
      .catch(this.handleError);
  }

  /* HANDLERS */

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* RENDERS */

  renderInstruction(instruction, i) {
    return (
      <div className="fm-instruction-group-instruction" key={i}>
        <div className="fm-instruction-group-instruction-header">
          <p className="fm-instruction-group-instruction-name">{instruction.name}</p>
          <p className="fm-instruction-group-instruction-lang">{instruction.lang}</p>
          <p className="fm-instruction-group-instruction-id">{instruction.id}</p>
        </div>
        <div className="fm-instruction-group-items">
          { instruction.items && instruction.items.map(this.renderItem) }
        </div>
      </div>
    );
  }

  renderItem(item, i) {
    return (
      <div className="fm-instruction-group-item" key={i}>
        <div className="fm-instruction-group-item-header">
          <div className="fm-instruction-group-item-photo" style={{ backgroundImage: `url('${item.photoUrl}')` }} />
          <p className="fm-instruction-group-item-id">{item.id}</p>
        </div>
        <p className="fm-instruction-group-item-title">{item.title}</p>
        <p className="fm-instruction-group-item-description">{item.description}</p>
      </div>
    );
  }

  render() {
    const { group, loading, error } = this.state;

    return (
      <div className="fm-instruction-group">
        <NavigationBar title={group.name || 'Instruction'} subtitle={group.id} loading={loading} showBack={true} />
        <div className="fm-instruction-group-container">
          { group.instructions && group.instructions.map(this.renderInstruction) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default InstructionGroup;
